'use strict';

function startMiniApp(shorthandVirtual) {
    const virtual = {};
    Object.keys(shorthandVirtual).forEach(property => {
        if (typeof shorthandVirtual[property] === 'function') {
            virtual[property] = {
                calculate: shorthandVirtual[property],
                format: x => x,
            }
        }
        else virtual[property] = {
            calculate: shorthandVirtual[property][0],
            format: shorthandVirtual[property][1],
        }
    });

    const pageModel = readModelFromPage();
    const savedModel = readModelFromStorage();
    const model = Object.assign({}, pageModel, savedModel);

    render(model);

    document.addEventListener('change', function() { onChange(); });
    document.addEventListener('input', function() { onChange(); });
    window.addEventListener('beforeunload', function() {
        saveModelToStorage(readModelFromPage());
    });

    function onChange() {
        render(readModelFromPage());
    }

    function render(model) {
        Object.keys(model).forEach(function(property) {
            const id = camelCaseToHyphenCase(property);
            const element = document.getElementById(id);
            if (element) {
                const value = model[property];
                // Only set if different, to prevent losing focus in current input.
                if (element.value !== value) element.value = value;
            }
            else console.warn('No element with ID: ' + property);
        });

        const virtualCalculators = {};
        Object.keys(virtual).forEach(property => {
            virtualCalculators[property] = virtual[property].calculate;
        });

        const virtualModel = Object.assign({}, model, virtualCalculators);
        Object.keys(virtual).forEach(function(property) {
            virtualModel[property] = function() {
                return virtualCalculators[property](virtualModel);
            };
        });

        Object.keys(virtualModel).forEach(function(property) {
            if (typeof virtualModel[property] !== 'function') return;

            const id = camelCaseToHyphenCase(property);
            const element = document.getElementById(id);
            if (element) {
                const value = virtualModel[property]();
                element.textContent = virtual[property].format(value);
            }
            else console.warn('No element with ID: ' + property);
        });
    }

    function readModelFromPage() {
        const inputs = [].slice.call(document.querySelectorAll('input'));
        const model = {};
        inputs.forEach(function(input) {
            if (input.hasAttribute('id')) {
                const id = input.getAttribute('id');
                const property = hyphenCaseToCamelCase(id);
                model[property] = input.value;
            }
        });
        return model;
    }

    function saveModelToStorage(model) {
        Object.keys(model).forEach(function(property) {
            const value = model[property];
            localStorage.setItem(property, value);
        });
    }

    function readModelFromStorage() {
        const model = {};
        for (let i = 0; i < localStorage.length; i += 1) {
            const property = localStorage.key(i);
            model[property] = localStorage.getItem(property);
        }
        return model;
    }

    function hyphenCaseToCamelCase(str) {
        return str.replace(/-./g, function(m) { return m[1].toUpperCase(); });
    }

    function camelCaseToHyphenCase(str) {
        return str.replace(/[A-Z]/g, function(m) { return '-' + m[0].toLowerCase(); });
    }
}
